import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

const CustomerLogos = () => {
    const [currentOneIndex, setCurrentOneIndex] = useState(0)
    const [currentTwoIndex, setCurrentTwoIndex] = useState(0)
    const [currentThreeIndex, setCurrentThreeIndex] = useState(0)
    const [currentFourIndex, setCurrentFourIndex] = useState(0)
    const [currentFiveIndex, setCurrentFiveIndex] = useState(0)
    const [currentSixIndex, setCurrentSixIndex] = useState(0)

    const animation = () => {
        let index = currentOneIndex + 1

        if (index > 3) {
            index = 0
        }

        setTimeout(() => setCurrentOneIndex(-1), 500)
        setTimeout(() => setCurrentTwoIndex(-1), 700)
        setTimeout(() => setCurrentThreeIndex(-1), 900)
        setTimeout(() => setCurrentFourIndex(-1), 1100)
        setTimeout(() => setCurrentFiveIndex(-1), 1300)
        setTimeout(() => setCurrentSixIndex(-1), 1500)

        setTimeout(() => setCurrentOneIndex(index), 600)
        setTimeout(() => setCurrentTwoIndex(index), 800)
        setTimeout(() => setCurrentThreeIndex(index), 1000)
        setTimeout(() => setCurrentFourIndex(index), 1200)
        setTimeout(() => setCurrentFiveIndex(index), 1400)
        setTimeout(() => setCurrentSixIndex(index), 1600)
    }

    useEffect(() => {
        const interval = setInterval(animation, 5000)

        return () => clearInterval(interval);
    }, [currentOneIndex])
    // }, [currentOneIndex, currentTwoIndex, currentThreeIndex, currentFourIndex, currentFiveIndex, currentSixIndex])

    return <div className="main__section">
        <div className="container container--np">
            <div className="carousel-logos">
                <div className="carousel-logos__customers">
                    <div className={`carousel-logos__customers__logo ${currentOneIndex === 0 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/telefonica.svg"}
                            alt="telefonica"
                            placeholder="blurred"
                            title="telefonica"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentOneIndex === 1 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/orange.svg"}
                            alt="orange"
                            title="orange"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentOneIndex === 2 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/o2.png"}
                            alt="o2"
                            title="o2"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentOneIndex === 3 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/holafly.svg"}
                            alt="holafly"
                            title="holafly"
                            placeholder="blurred"
                        />
                    </div>
                </div>

                <div className="carousel-logos__customers">
                    <div className={`carousel-logos__customers__logo ${currentTwoIndex === 0 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/prosegur.svg"}
                            alt="prosegur"
                            title="prosegur"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentTwoIndex === 1 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/habitissim.svg"}
                            alt="habitissimo"
                            title="habitissimo"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentTwoIndex === 2 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/renfe.svg"}
                            alt="renfe"
                            title="renfe"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentTwoIndex === 3 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/arcopay.svg"}
                            alt="arcopay"
                            title="arcopay"
                            placeholder="blurred"
                        />
                    </div>
                </div>

                <div className="carousel-logos__customers">
                    <div className={`carousel-logos__customers__logo ${currentThreeIndex === 0 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/santander.svg"}
                            alt="santander"
                            title="santander"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentThreeIndex === 1 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/the-power-mba.svg"}
                            alt="the-power-mba"
                            title="the-power-mba"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentThreeIndex === 2 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/simyo.svg"}
                            alt="simyo"
                            title="simyo"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentThreeIndex === 3 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/goal.svg"}
                            alt="goal"
                            title="goal"
                            placeholder="blurred"
                        />
                    </div>
                </div>

                <div className="carousel-logos__customers">
                    <div className={`carousel-logos__customers__logo ${currentFourIndex === 0 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/vodafone.svg"}
                            alt="vodafone"
                            title="vodafone"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentFourIndex === 1 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/europa_press.svg"}
                            alt="europa_press"
                            title="europa_press"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentFourIndex === 2 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/as-energy.svg"}
                            alt="as-energy"
                            title="as-energy"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentFourIndex === 3 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/phone-house.svg"}
                            alt="the-phone-house"
                            title="the-phone-house"
                            placeholder="blurred"
                        />
                    </div>
                </div>

                <div className="carousel-logos__customers">
                    <div className={`carousel-logos__customers__logo ${currentFiveIndex === 0 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/enel.svg"}
                            alt="enel"
                            title="enel"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentFiveIndex === 1 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/alquiler-seguro.svg"}
                            alt="alquiler-seguro"
                            title="alquiler-seguro"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentFiveIndex === 2 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/dominion.svg"}
                            alt="dominion"
                            title="dominion"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentFiveIndex === 3 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/software-one.svg"}
                            alt="software-one"
                            title="software-one"
                            placeholder="blurred"
                        />
                    </div>
                </div>

                <div className="carousel-logos__customers">
                    <div className={`carousel-logos__customers__logo ${currentSixIndex === 0 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/claro.svg"}
                            alt="claro"
                            title="claro"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentSixIndex === 1 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/grupo_mas_movil.svg"}
                            alt="grupo_mas_movil"
                            title="grupo_mas_movil"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentSixIndex === 2 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/mercedes.svg"}
                            alt="mercedes"
                            title="mercedes"
                            placeholder="blurred"
                        />
                    </div>
                    <div className={`carousel-logos__customers__logo ${currentSixIndex === 3 ? "carousel-logos__customers__logo--show" : ""}`}>
                        <StaticImage
                            src={"../../../new/resources/home/clients/finastra.svg"}
                            alt="finastra"
                            title="finastra"
                            placeholder="blurred"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
 export default CustomerLogos
