import React, { useEffect, useState } from "react"
import SectionFormDemo from "../SectionFormDemo/sectionformdemo"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const ReadyToTalk = ({ lang = "en", title, description, hideTags = false, form }) => {
    const [formLoaded, setFormLoaded] = useState(false);

    useEffect(() => {
        if (!formLoaded) {
            setFormLoaded(true);
        }
    }, []);

    const query = useStaticQuery(graphql`query {
        rad: allMarkdownRemark( filter: { frontmatter: { name: { eq: "ready-to-talk" } } } ) {
            edges {
                node {
                    frontmatter {
                        rad {
                            title
                            description
                            tag_1
                            tag_2
                            tag_3
                            tag_4
                        }
                        lang
                    }
                }
            }
        }
    }`);
    const rad = query.rad.edges.filter((item) => item.node.frontmatter.lang === lang)[0].node.frontmatter
    if (!title) {
        title = rad.rad.title
    }
    if (!description) {
        description = rad.rad.description
    }

    return <div className="main__section main__section--50 highlight-section purple" id="ready-to-talk">
        <div className="container">
            <div className="container__50 text--center">
                <div className="title-xl">
                    {title}
                </div>
                <div className="container__text fs-24">
                    <ReactMarkdown
                        children={description}
                        rehypePlugins={[rehypeRaw]}
                    />
                </div>
                {!hideTags && 
                    <div className="tag-group hidden-lg">
                        <div className="tag tag--highlight tag--highlight--md ghost">{rad.rad.tag_1}</div>
                        <div className="tag tag--highlight tag--highlight--md ghost">{rad.rad.tag_2}</div>
                        <div className="tag tag--highlight tag--highlight--md ghost">{rad.rad.tag_3}</div>
                        <div className="tag tag--highlight tag--highlight--md">{rad.rad.tag_4}</div>
                    </div>
                }
            </div>
            <div className="container__50">
                <div className="tile tile--white">
                    <SectionFormDemo lang={lang} form={form} />
                </div>
            </div>
        </div>
    </div>
}

export default ReadyToTalk
